import React, { useState } from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Divider, Grid, Tabs, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import StyledTab from '../../custom/StyledTab';
import PrimaryMerchant from './PrimaryMerchant';
import SecondaryMerchant from './SecondaryMerchant';
import SharedMembers from './SharedMembers';

const MapSquareMembership = () => {
  const [formTab, setTab] = useState(0);

  return (
    <div>
      <Box pb={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5">
              Map Merchants IDs - Memberships / Coupons
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Tabs
          value={formTab}
          onChange={(event, newValue) => {
            setTab(newValue);
          }}
          TabIndicatorProps={{ style: { background: '#0969da' } }}
          aria-label="Select settings tab"
        >
          <StyledTab
            disableRipple
            id="primary"
            aria-controls="values"
            label="Primary Merchants"
          />
          <StyledTab
            disableRipple
            id="secondary"
            aria-controls="values"
            label="Secondary Merchants"
          />
          <StyledTab
            disableRipple
            id="members"
            aria-controls="values"
            label="List of Members and Discount Codes"
          />
        </Tabs>
      </Box>
      <Divider />
      <TabContext value={formTab.toString()}>
        <SwipeableViews index={formTab} onChangeIndex={setTab}>
          <TabPanel value={formTab.toString()} index={0} style={{ padding: 0 }}>
            {formTab === 0 ? <PrimaryMerchant /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={1} style={{ padding: 0 }}>
            {formTab === 1 ? <SecondaryMerchant /> : null}
          </TabPanel>
          <TabPanel value={formTab.toString()} index={2} style={{ padding: 0 }}>
            {formTab === 2 ? <SharedMembers /> : null}
          </TabPanel>
        </SwipeableViews>
      </TabContext>
    </div>
  );
};
export default MapSquareMembership;
